import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import * as JobPostsStyles from "./jobPosts.module.css"
import { Container, Row, Col, Card } from "react-bootstrap"
import { Fragment } from "react"

const PostsWrapper = () => (
  <StaticQuery
    query={graphql`
      query {
        allMysqlPosts(sort: { fields: publish_at, order: DESC }) {
          nodes {
            mysqlId
            slug
            type_id
            vacancy_type
            vacancy_subtitle
            vacancy_the_role
            vacancy_the_company
            vacancy_salary_amount
            vacancy_salary
            vacancy_reference
            vacancy_location
            vacancy_benefits
            work_in_uk
            vacancy_candidate_requirements
            enabled
            featured
          }
        }
      }
    `}
    render={data => {
      const posts = data.allMysqlPosts.nodes

      return (
        <>
          {posts.map((post, i) => (
            <React.Fragment key={i}>
              {post.enabled === 1 && post.featured === 1 ? (
                <Col sm={4}>
                  <Link
                    style={{ textDecoration: "none", color: "#ce5f26" }}
                    to={"/" + post.slug}
                  >
                    <Card className={JobPostsStyles.card}>
                      <Card.Header className={JobPostsStyles.cardTextHeader}>
                        {post.vacancy_type}
                      </Card.Header>
                      <Card.Body className={JobPostsStyles.cardText}>
                        <Card.Text>{post.vacancy_subtitle}</Card.Text>
                      </Card.Body>
                      <Card.Header className={JobPostsStyles.cardTextLocation}>
                        {post.vacancy_location}
                      </Card.Header>
                      <Card.Header className={JobPostsStyles.cardTextSalary}>
                        {post.vacancy_salary_amount}
                      </Card.Header>
                    </Card>
                  </Link>
                </Col>
              ) : null}
            </React.Fragment>
          ))}
        </>
      )
    }}
  />
)

const JobPosts = () => {
  return (
    <>
      <main>
        <Container className={JobPostsStyles.postsContainer}>
          <Row>
            <PostsWrapper />
          </Row>
        </Container>
      </main>
    </>
  )
}

export default JobPosts
