import * as React from "react"
import Layout from "../components/layout"
// import PageContent from "../components/pageContent"
import JobPosts from "../components/jobPosts"
import Seo from "../components/seo"
import * as pageStyles from "../components/pageContent.module.css"

const IndexPage = () => (
  <Layout splashPage={true} pageTitle="CATERING & HOSPITALITY RECRUITMENT">
    <Seo title="Home" />

    {/* <PageContent /> */}
    <div className={pageStyles.pageHeader}>Latest vacancies</div>
    <JobPosts />
  </Layout>
)

export default IndexPage
